
h1 {
  padding-bottom: 20px;
}

header.main-header {
  background: #e5e6e7;
  margin-bottom: 15px;

  .logo-img {
    padding: 15px 0;
  }

  ul.navbar-nav {
    li.dropdown {
      padding-top: 20px;

      a.dropdown-toggle {
        color: #666;
        font-size: 1.2em;
        .fa {
          padding-right: 6px;
          color: #999;
        }
        .caret {
          margin-left: 5px;
        }
      }
    }
  }

  .main-nav {
    background: #5089c9;

    ul.navbar-nav {
      li {
        a, a:active, a:visited, a:hover {
          color: #fff;
          font-size: 1.1em;
          padding-left: 20px;
          padding-right: 20px;
        }
        a:hover {
          background: #f3952a;
        }
      }
    }
  }
}

.btn {
  > i.icon-button {
    padding-left: 10px;
  }
  &.btn-primary {
    background: #5089c9;
  }
  border-radius: 0;
  padding: 7px 25px;
}

.panel > .panel-heading{
  background-color: #5089c9;
  color:#fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.search-form {
  background: #f1f0f2;
  margin-bottom: 25px;

  h4 {
    font-size: 1.5em;
    display: inline-block;
    padding-right: 20px;
  }
  .form-group {
    padding: 15px 15px;
  }
}

/***
Bootstrap Wizard
***/
.form-wizard .progress {
  margin-bottom: 30px; }

.form-wizard .steps {
  padding: 10px 0;
  margin-bottom: 15px;
  background-color: #fff;
  background-image: none;
  filter: none;
  border: 0px;
  box-shadow: none; }
.form-wizard .steps > li > a.step {
  background-color: #fff;
  background-image: none;
  filter: none;
  border: 0px;
  color: #333;
  box-shadow: none; }
.form-wizard .steps > li > a.step:hover {
  background: none; }
.form-wizard .steps > li > a.step > .number {
  background-color: #eee;
  display: inline-block;
  text-align: center !important;
  font-size: 16px;
  font-weight: 300;
  padding: 11px 15px 13px 15px;
  margin-right: 10px;
  height: 45px;
  width: 45px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important; }
.form-wizard .steps > li > a.step > .desc {
  display: inline-block;
  font-size: 16px;
  font-weight: 300; }
.form-wizard .steps > li > a.step > .desc > i {
  display: none; }
.form-wizard .steps > li.active > a.step .number {
  background-color: #5ec85d;
  color: #fff; }
.form-wizard .steps > li.active > a.step .desc {
  color: #333; }
.form-wizard .steps > li.done > a.step .number {
  background-color: #f3952a;
  color: #fff; }
.form-wizard .steps > li.done > a.step .desc {
  color: #333; }
.form-wizard .steps > li.done > a.step .desc i {
  font-size: 12px;
  font-weight: normal;
  color: #999;
  display: inline-block; }

@media (min-width: 768px) and (max-width: 1200px) {
/* 768px & 1200px */
  .form-wizard .step .desc {
    margin-top: 10px;
    display: block; } }

@media (max-width: 768px) {
/* 768px */
  .form-wizard .steps > li > a {
    text-align: left; } }

.portlet {
  .portlet-title {
    background: #5089c9;
    color: #fff;
    font-size: 1.3em;
    padding: 10px;
  }

  &.bordered {
    border: 1px solid #e5e6e7;
  }

  .portlet-body {
    padding: 20px;
  }
}

.form-action-container {
  background: #f1f0f2;
  padding: 30px 0;
}

#director-wizard {
  margin-bottom: 30px;
}

.other-software {
  border: 1px solid #e5e6e7;
  padding: 10px;

  &.alert-box {
    border-color: #b94a48;
  }

  p {
    font-weight: bold;
    font-size: 0.9em;
  }
  h3 {
    padding: 5px;
    font-weight: bold;
    margin-top: 0;
    &.alert {
      color: #b94a48;
      margin-bottom: 5px;
    }

    i {
      font-size: 1.3em;
      padding-right: 7px;
      line-height: 1.4em;
    }
  }

  .inner-alert {
    border: 1px solid #e5e6e7;
    padding: 10px;
    .link a {
      display: inline-block;
      padding-top: 5px;
      font-weight: bold;
      color: #666;
      font-size: 0.9em;
    }

  }
}

.file-downloads {
  .file-row {
    margin-bottom: 5px;
  }
  .file-download-item {
    background: #e5e6e7;

    @media screen and (max-width: 767px) {
      font-size: 0.7em;
      padding-left: 5px;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 0.7em;
      padding-left: 5px;
    }

      a {
      padding: 10px;
      color: #666666;
      display: block;
    }
    .fa {
      float: right;
      font-size: 1.4em;
    }
  }

  @media screen and (max-width: 767px) {
    .file-upload {
      margin-top: 5px;
    }
  }
}


$file-upload-color: #5ec85d;
$file-upload-size: 300px;

.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}
.custom-file-upload {
  display: block;
  width: auto;
  font-size: 16px;
  //border: 1px solid #ccc;
  label {
    display: block;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 767px) {
    height:45px;
  }

}

.file-upload-wrapper {
  position: relative;
}
.file-upload-input {
  width: $file-upload-size;
  color: #fff;
  font-size: 1em;
  padding: 9px 17px;
  border: none;
  /*background-color: $file-upload-color;*/
  background-color: #eee;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  float: left; /* IE 9 Fix */
  &.has-value {
    background-color: darken($file-upload-color, 5);
    outline: none;
  }
  &:hover, &:focus {
  }

  @media screen and (max-width: 480px) {
    width: 80px;
    padding: 9px 5px 10px 5px;
    font-size: 0.65em;
  }

  @media screen and (min-width: 481px) and (max-width: 767px) {
    width:120px;
    padding: 11px 5px 10px 5px;
    font-size: 0.7em;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    width:165px;
    padding: 9px 5px;
    font-size: 0.8em;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    width:255px;
    padding: 11px 5px;
    font-size: 0.8em;
  }
}
.file-upload-button {
  cursor: pointer;
  display: inline-block;
  color: #fff;
  font-size: 1em;
  text-transform: uppercase;
  padding: 9px 20px;
  border: none;
  margin-left: -1px;
  background-color: darken($file-upload-color, 10);
  float: left; /* IE 9 Fix */
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: darken($file-upload-color, 20);
  }
  @media screen and (max-width: 480px) {
    font-size: 0.7em;
  }
  @media screen and (min-width: 481px) and (max-width: 767px) {
    font-size: 0.8em;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 0.8em;
  }

}

.tab-content {
  .tab-pane {
    padding-left: 15px;
    padding-right: 15px;

    .dox_id_type {
      margin-left: 13px;
    }
  }
}

.padded-messages {
  padding: 10px 20% 10px 20%;
  font-weight: bold;
  @media screen and (max-width: 480px) {
    padding: 10px 5% 10px 5%;
  }

  @media screen and (min-width: 481px) and (max-width: 767px) {
    padding: 10px 10% 10px 10%;
  }
}

.huge-icon {
  color: #5ec85d;
  padding: 10px 0;
  i {
    font-size: 6em;
  }
}

i.fa {
  &.lg-icon {
    font-size: 1.6em;
  }
}

.status-review {
  padding-bottom: 20px;

  h3 {
    padding: 10px 0 20px 0;
  }
  h4 {
    padding-bottom: 10px;
  }
  .status-options {
    padding: 0 0 10px 0;

    .radio-inline {
      font-weight: bold;
    }
  }
}

#edit-container {
  margin-bottom: 30px;
}

#register-container {
  .portlet {
    margin: 40px 0;
  }
  .intro-text {
    padding: 40px;

    .headline {
      font-size: 1.3em;
      font-weight: bold;
      padding-bottom: 20px;
    }

    .sub-text {
      p {
        padding: 7px 0;
        font-size: 1.1em;
        a, a:hover, a:active, a:focus {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
}

.form-horizontal .has-feedback .title-group .form-control-feedback {
  right: -45px;
  z-index: 1;
}

.form-group.required .control-label:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 8px;
  top:7px;
}

.documentation-review {
  padding: 0 30px 0 30px;

  @media screen and (max-width: 480px) {
    padding: 0 5px 0 5px;
  }

  @media screen and (min-width: 481px) and (max-width: 767px) {
    padding: 0 5px 0 5px;
  }
}
.document-upload-form {
  h3.block {
    padding-bottom: 10px;
  }

  .form-group.slim {
    margin-bottom: 5px;

    .custom-file-upload {
      padding-left: 10px;
    }
    .dox_id_type {
      margin-left: 10px;
    }
  }
  .file-status-row {
    margin-bottom: 5px;
    clear:both;
    span.filename {
      padding-left: 10px;
    }

  }

  .label-col {
    .control-label {
      padding-left: 15px;
    }
  }

  .file-row {
    .rejected_dox {
      border: 1px solid #d9534f;
      font-size: 0.9em;
      @media screen and (max-width: 992px) {
        font-size: 0.7em;
      }

        a {
        padding-left: 0;
      }
      span.label {
        margin-right: 10px;
      }
    }
  }
}


.ui-datepicker-title {
  select, select.ui-datepicker-month, select.ui-datepicker-year {
    color: #000;
  }
}

.start-box {
  &.centered {
    text-align: center!important;
  }
  img {
    max-width: 155px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    font-size: 1.4em;
  }
}