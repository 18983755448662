
body.login {
  background: #e5e6e7;
}

.login {
  .logo {
    margin: 60px auto 0;
    padding: 15px;
    text-align: center;
    img {
      margin-left: -70px;
    }
  }

  h3.form-title {
    padding-bottom: 30px;
  }
  .content {
    background-color: #fff;
    width: 400px;
    margin: 0px auto 10px;
    overflow: hidden;
    position: relative;

    .inner {
      padding: 10px 30px 30px;
    }

    .forgot {
      border-top: 1px solid #e5e6e7;
      padding: 10px 30px 30px;
    }

    .login-form {
      margin: 0;
      padding: 0;
    }
  }

  .form-actions {
    .rememberme {
      padding-top: 5px;
      display: inline-block;
      width: 120px;
    }
    .login-btn {
      span {
        padding-left: 10px;
      }
    }
  }


  // Feedback icon
  .form-control-feedback {
    width: 38px;
    color: #999;
    border-left: 2px solid #5089c9;

    input[class*=bg-] + & {
      color: #fff;
    }
  }

  // Left icon position
  .has-feedback-left {
    .form-control {
      padding-right: 12px;
      padding-left: 36px;
    }
    .form-control-feedback {
      right: auto;
      left: 0;
    }
  }
}